import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateIP } from "../../actions/ipAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class IpUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            whitelist: this.props.record.whitelist,
            errors: {},
           };
    }
    componentDidMount(props){
        console.log("data :",this.props.record)
    }

    componentWillReceiveProps(nextProps) {
         console.log("next Props>>>>",nextProps)
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                whitelist: nextProps.record.whitelist,
               
            })
           // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {
           
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
            $('#update-ipaddress-modal').modal('hide');
        
    }

    onChange = e => {
        // alert(e.target.id)
         if (e&&e.target&&e.target.id === 'update-ipaddress-modal') {
            this.setState({ whitelist: e.target.value });
         }
      
    };
   
   
    onIPUpdate = e => {
        e.preventDefault();
        const updateIp = {
            _id: this.state.id,
            whitelist: this.state.whitelist,
             };
        this.props.updateIP(updateIp);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-ipaddress-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update IP Address</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onIPUpdate} id="update-ip">
                                    <input
                                        onChange={(e)=>this.onChange(e)}
                                        value={this.state.id}
                                        id="ip-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">IP Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.whitelist}
                                                id="update-ipaddress-modal"
                                                type="text"
                                                error={errors.whitelist}
                                                className={classnames("form-control", {
                                                    invalid: errors.whitelist
                                                })}/>
                                            <span className="text-danger">{errors.whitelist}</span>
                                        </div>
                                    </div>
                         </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-ip"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update IP Address
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

IpUpdateModal.propTypes = {
    updateIP: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateIP }
)(withRouter(IpUpdateModal));
