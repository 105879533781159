import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    STAKE_ADD,
    STAKE_UPDATE
} from "./types";

export const addStake = (param, history) => dispatch => {
    var stakeData = new FormData();
    stakeData.append('risk',5 );
    stakeData.append('pid',0);
    stakeData.append('lpSymbol',param.tokenSymbol);
    stakeData.append('alloc',param.alloc*100 );
    stakeData.append('isTokenOnly',true );
    stakeData.append('lpAddresses',param.lpAddresses );
    stakeData.append('tokenSymbol',param.tokenSymbol );
    stakeData.append('tokenAddresses',param.tokenAddresses );
    stakeData.append('quoteTokenSymbol',"BUSD");
    stakeData.append('quoteTokenAdresses',"0xe9e7cea3dedca5984780bafc599bd69add087d56" );
    stakeData.append('depositFee',param.depositFee );
    stakeData.append('reward_token',param.reward_token);
    stakeData.append('locked_period',param.locked_period);
    stakeData.append('RewardPerInterval',param.RewardPerInterval);
    stakeData.append('file',param.file );

//console.log("formdata>>>>>>>",formData)
    axios
        .post(backurl+"/api/Stake-add", stakeData)
        .then(res =>{   
            // console.log("yyyhyu>>",res)
            dispatch({
                type: STAKE_ADD,
                payload: res,
            })
        }
        ).catch(err =>{   
            // console.log("yyyhyu>>",err)
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updateStake = (param) => dispatch => {
    console.log("updatePool>>>>>",param)
    var stakeUpdateData = new FormData();
    if (!isEmpty(param._id))   stakeUpdateData.append('_id',param._id );
      stakeUpdateData.append('pid',0);
    if (!isEmpty(param.risk))   stakeUpdateData.append('risk',param.risk );
    stakeUpdateData.append('lpSymbol',param.tokenSymbol);
    if (!isEmpty(param.alloc)) stakeUpdateData.append('alloc',param.alloc*100 );
       stakeUpdateData.append('isTokenOnly',true);
    if (!isEmpty(param.lpAddresses)) stakeUpdateData.append('lpAddresses',param.lpAddresses);
    if (!isEmpty(param.tokenSymbol)) stakeUpdateData.append('tokenSymbol',param.tokenSymbol);
    if (!isEmpty(param.tokenAddresses))stakeUpdateData.append('tokenAddresses',param.tokenAddresses);
    if (!isEmpty(param.quoteTokenSymbol)) stakeUpdateData.append('quoteTokenSymbol',"BUSD");
    if (!isEmpty(param.quoteTokenAdresses))stakeUpdateData.append('quoteTokenAdresses',"0xe9e7cea3dedca5984780bafc599bd69add087d56" );
    if (!isEmpty(param.depositFee))stakeUpdateData.append('depositFee',param.depositFee);
    if (!isEmpty(param.reward_token))stakeUpdateData.append('reward_token',param.reward_token);
    if (!isEmpty(param.locked_period))stakeUpdateData.append('locked_period',param.locked_period);
    if (!isEmpty(param.RewardPerInterval))stakeUpdateData.append('RewardPerInterval',param. RewardPerInterval);
   
    if (!isEmpty(param.file)) {
        stakeUpdateData.append('file',param.file );
        console.log('1111111',stakeUpdateData);
    }
    else {
        console.log('222222222');
    }
    // console.log("stakeUpdateData>>>>>",stakeUpdateData)
    axios
        .post(backurl+"/api/Stake-update", stakeUpdateData)
        .then(res =>
            dispatch({
                type: STAKE_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
