import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    IP_ADD,
    IP_UPDATE
} from "./types";

export const addIpaddress = (param, history) => dispatch => {
    var ipData = new FormData();
   
    ipData.append('whitelist',param.whitelist);
    

//console.log("formdata>>>>>>>",formData)   
    axios
        .post(backurl+"/api/ip-add", ipData)
        .then(res =>{   
           
            dispatch({
                type: IP_ADD,
                payload: res,
            })
        }
        ).catch(err =>{   
           
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updateIP = (param) => dispatch => {
    console.log("formUpdateData;;;;;;;>>>>>2",param)
    var req = param
    // console.log("formUpdateData;;;;;;;>>>>>2",id,wl)
    // var ipUpdateData = new FormData();
    // ipUpdateData.append('_id',id);
    // ipUpdateData.append('whitelist',wl);
    console.log("formUpdateData;;;;;;;>>>>>",req)
    axios
        .post(backurl+"/api/ip-update", req)
        .then(res =>
            dispatch({
                type: IP_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
