
//    const env_name = "local"
    const env_name = "live"
var backurl = ""
if(env_name === "local"){
    backurl = "http://localhost:2053"
}
else{
    backurl = "https://metaswap.click" // mainet

    // backurl ="http://43.204.99.101:7011" // testnet
}

export default backurl;